
import React, { useState } from 'react';
import logo from '../assets/images/logo.png'
import cart from '../assets/icons/cart.png'
import menu from '../assets/icons/Menu.png'

function Navbar() {

    const [isOpen, setIsOpen] = useState(false)

  return (
    <div className='drop-shadow-sm bg-white'>
        <div className="banner text-center bg-black text-white py-1 text-[14px]">
            <p>50% Offer - Free Shipping</p>
        </div>
        <nav className='max-w-[80rem] mx-auto px-[24px] flex flex-row gap-5 justify-between items-center py-[5px] '>
            
            <div className="w-[30%] mob-view">
            <img src={menu} alt='icon' onClick={ ()=> setIsOpen(!isOpen)} />
            </div>

            <ul className='flex flex-row gap-5 w-[30%] desk-view'>
                <li className=''>
                    <a href={'/'}>Home </a>
                </li>
            </ul>
            <div className='w-[30%]'>
                <img className='h-[65px] w-[auto] mx-auto' src={logo} alt='logo'/>
            </div>
            <div className='w-[30%]'>
                <a href="https://icycubs.shop/products/ice-cube"><img className='w-[32px] ml-auto' src={cart} alt='logo'/></a>
            </div>
        </nav>

        {/* mobile Nav */}
        {isOpen && <nav className='h-[100vh] w-full fixed top-0 left-0 bg-opacity z-[100] flex flex-row'>
            <ul className='bg-white h-full w-[80%] flex flex-col gap-5 p-[24px]'>
                <li className='font-bold'>
                    <a onClick={() => setIsOpen(false)} href={'/'}>Home</a>
                </li>
            </ul>
            <div onClick={()=> setIsOpen(false)} className="w-[20%] h-full"></div>
        </nav>
        }
    </div>
  );
}


export default Navbar;
