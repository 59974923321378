import React from 'react'
import phone from '../assets/icons/Phone.png'
import email from '../assets/icons/Email.png'
import address from '../assets/icons/Address.png'
import banner from '../assets/images/banner-img.png'
import img1 from '../assets/images/img1.jpeg'
import vid1 from '../assets/videos/vid1.mp4'
import vid2 from '../assets/videos/vid2.mp4'
export default function Home() {
  return (
    <>
    <section className="max-w-[80rem] mx-auto gradiant-bg mt-[24px] lg:mt-[150px] lg:rounded-[20px] w-full">
        <div className="px-[24px] flex flex-col lg:flex-row gap-5 justify-between lg:relative w-full">
            <div className="p-[24px] lg:w-[50%]">
                <h1 className="font-bold text-white text-[32px] mb-5">1 Step into cup 0 touch the ice</h1>
                <p className="mb-10 text-[white] w-full">
                    Ice Cube One Touch Release With the Ice Tray, you can easily remove all the ice cubes with just one press. Does not need a lot of space You can easily fill it and put it in the freezer or refrigerator to find it after minutes ready to use and enjoy a cold and delicious drink.
                </p>
                <a className="rounded-[50px] border-2 border-[#30A0CD] px-5 py-2 text-[#30A0CD] hover:bg-[#30A0CD] hover:text-[white]" href={'https://icycubs.shop/products/ice-cube'}>
                    Shop Now!
                </a>
                </div>
                <div className="lg:w-[30%] h-[250px] lg:h-[350px]">
                <img className="w-[250px] lg:w-[500px] ml-auto lg:absolute right-0 bottom-0 rounded-[20px]" src={banner} alt="banner image" />
            </div>
        </div>
      </section>
      <section className='max-w-[80rem] gap-5 mx-auto p-[24px] lg:p-[0] mt-[70px] flex flex-col lg:flex-row w-full'>
        <video className='h-[500px]' autoPlay loop muted>
            <source src={vid1} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
        <img className='h-[auto] lg:h-[500px]' src={img1} alt="img" />
        <video className='h-[auto] lg:h-[500px]' autoPlay loop muted>
            <source src={vid2} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
      </section>
        <div className='text-center mt-10'>
        <a className="rounded-[50px] border-2 border-[#30A0CD] mt-2 px-5 py-2 text-[#30A0CD] hover:bg-[#30A0CD] hover:text-[white]" href={'https://icycubs.shop/products/ice-cube'}>
                    Shop Now!
        </a>
        </div>
        <div className="max-w-[80rem] mx-auto p-[24px] lg:p-[0] mt-[70px] flex flex-col lg:flex-row w-full">
            <div className="lg:w-[50%] mb-10">
                <h2 className="text-[24px] lg:text-[32px] font-bold mb-10">Let’s Keep in Touch</h2>
                <p className="font-bold flex flex-row gap-2"><img className="w-[24px] h-[24px]" src={phone} alt="icon" />Phone: <span className="font-normal text-[gray]">+111111111111</span></p>
                <p className="font-bold flex flex-row gap-2 my-2"><img className="w-[24px] h-[24px]" src={address} alt="icon" />Address: <span className="font-normal text-[gray]">30 N Gould STE4000 Sheridan, Wyoming 82801</span></p>
                <p className="font-bold flex flex-row gap-2"><img className="w-[24px] h-[24px]" src={email} alt="icon" />E-Mail: <span className="font-normal text-[gray]">support@sandyfortrading.com</span></p>
            </div>
            <div className="lg:w-[50%]">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2831.1680861235045!2d-106.9549523!3d44.797762999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5335fabc2a650d27%3A0x3092109ed30c1089!2s30%20N%20Gould%20St%20%234000%2C%20Sheridan%2C%20WY%2082801%2C%20USA!5e0!3m2!1sen!2seg!4v1720744086022!5m2!1sen!2seg" width="100%" height="350" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>    
        </div>
    
    </>
  )
}
