import { useState } from 'react'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import {BrowserRouter as Router ,Route, Routes } from 'react-router-dom';
import './App.css'
import Home from './pages/Home';
import Contact from './pages/Contact';

function App() {
  const [count, setCount] = useState(0)

  return (
    // <Router>
      <main className="min-h-[100vh] flex flex-col justify-between">
        <Navbar />
        <Home />
        {/* <Routes>
          <Route path='/' Component={Home} />
          <Route path='/contact' Component={Contact} />
        </Routes> */}
        
        <Footer />
      </main>
    // </Router>
  )
}

export default App
