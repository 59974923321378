// import Link from 'next/link';
import React from 'react';
import facebook from '../assets/icons/Facebook.png'
import instagram from '../assets/icons/Instagram.png'
import linkedin from '../assets/icons/LinkedIn.png'
// import Image from 'next/image';

export default function Footer() {
  return (
    <div className='px-[24px] lg:px-[140px] pt-[32px] text-center gradiant-bg text-white mt-10'>
        <div className="socials flex flex-row gap-5 justify-center mb-5">
            <a href={'#'}><img src={facebook} alt='img' /></a>
            <a href={'#'}><img src={instagram} alt='img' /></a>
            <a href={'#'}><img src={linkedin} alt='img' /></a>
        </div>
        <hr />
        <p className='py-3 text-[10px] lg:text-[14px]'>
        Copyright &copy; 2024 All Rights Reserved By SANDY for TRADING.
        </p>
    </div>
  );
}
